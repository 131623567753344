import React, { useState } from "react"
import * as styles from "./css/about.module.styl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { widthInfo } from "../util/index.js"
import { useTranslation } from 'gatsby-plugin-react-i18next';

let width = widthInfo()
const AboutUs = () => {
  const callNumbers = [
    [
      '2138284000',
      '2138284001-2138284010',
    ],
    [
      '2138352000',
      '2138352001-2138352010',
    ],
    [
      '36493011',
      '36492200-36492249',
      '36495000-36495999',
    ],
    [
      '36493012',
      '36494000-36494999',
    ],
  ]
  const mobileCallNumbers = [
    [
      '2138284000',
    ],
    [
      '2138284001-2138284010',
    ],
    [
      '2138352000',
    ],
    [
      '2138352001-2138352010',
    ],
    [
      '36493011',
    ],
    [
      '36492200-36492249',
    ],
    [
      '36495000-36495999',
    ],
    [
      '36493012',
    ],
    [
      '36494000-36494999',
    ],
  ]

  const initialData = {
    callNumbers,
    mobileCallNumbers
  }
  const [data, setState] = useState(initialData)

  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="AboutUs" />
      <div className={styles.about}>
        <div className={styles.aboutBan}>
          <div className={`${styles.aboutBanInner} ${styles.containerAbout}`}>
            <div className={styles.aboutBanPara}>
              {t('banner.title')}
            </div>
            <a
              aria-label="aboutUs download"
              className={styles.aboutBanButton}
              href="https://play.google.com/store/apps/details?id=com.loan.cash.credit.barwaqt.tez.paisa.jazz.financial.ready.easy"
            >
              {" "}
            </a>
            {
              width < 750 ? (
                <a aria-label="index download" className={styles.mobileSeedcredLink} target="_blank" href="https://www.seedcredfintec.com/"></a>
              ) : null
            }
          </div>
        </div>
        <h3 className={styles.aboutTitle}>{t('about.title')}</h3>
        <p className={`${styles.aboutInfo} ${styles.containerAbout}`}>
        {t('about.desc')}
        </p>

        <h3 className={styles.aboutVisionTitle}>{t('vision.title')}</h3>

        <section className={`${styles.aboutVisionInfo} ${styles.containerAbout}`}>
          <p>
            {t('vision.desc.para1')}
          </p>
          <p>
            {t('vision.desc.para2')}
          </p>
        </section>

        

        <section className={styles.aboutDisclosureContainer}>
          <h3 className={styles.aboutDisclosureTitle}>{t('info.title')}</h3>
          <div className={`${styles.containerAbout} ${styles.aboutDisclosureList}`}>
            <ul className={styles.aboutDisclosureTopInfo}>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item1')}</span>
              </li>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item2')}</span>
              </li>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item3')}</span>
              </li>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item4')}</span>
              </li>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item5')}</span>
              </li>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item6')}</span>
              </li>
            </ul>
            <ul className={styles.aboutDisclosureBottomInfo}>
              <li className={styles.aboutDisclosureItem}>
                <span>{t('info.desc.item7')}</span>
              </li>
            </ul>
            <section className={styles.callNumber}>
              <p>{t('info.callNumber.title')}</p>
              <div className={styles.numbers}>
                <ul>
                  {
                    (width > 1024 ? data.callNumbers : data.mobileCallNumbers).map((numbers, idx) => (
                      <li key={idx}>
                        {
                          numbers.map((number, idx) => (
                            <span key={idx}>{number}</span>
                          ))
                        }
                      </li>
                    ))
                  }
                </ul>
              </div>
            </section>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
